import React, {
  forwardRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { format, render, cancel, register } from "timeago.js";
import AxiosAPI from "../../../../helpers/AxiosAPI";
import { Dropdown, MenuProps, message, Modal, Popover, Tag } from "antd";
import {
  CaretDownOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleFilled,
  MailOutlined,
  PhoneOutlined,
  SlackOutlined,
  TeamOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
//import BuyerSellerList from "./BuyerSellerList";
import { MdInfoOutline } from "react-icons/md";
interface DocumentListItemProps {
  children?: React.ReactNode;
  listViewLabel: string;
  selectedRFQ: any;
  getTime: any;
  inquiryRespondingCompany: any;
}

const toTitleCase: any = (str: string): string => {
  return str
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

const BuyerInquiryDetails: any = ({ children, ...props }) => {
  const { selectedRFQ, getTime, inquiryRespondingCompany }: any = props;

  const [suppliers, setSuppliers] = React.useState<any>([]);

  const [currentSupplierName, setCurrentSupplierName] = useState("");

  const [currentDescription, setCurrentDescription] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    AxiosAPI.getBuyerSellers()
      .then((res: any) => {
        //console.error(":::: SELLER FETCH RESULT ", res.data);
        setSuppliers(res.data);
      })
      .catch((error: any) => {
        //console.error(":::: SELLER FETCH ERROR ", error);
        setSuppliers([]);
      });
  }, [selectedRFQ]);

  useEffect(() => {
    String(props.listViewLabel) === "RFQ"
      ? setCurrentDescription(selectedRFQ.productDetails)
      : setCurrentDescription(selectedRFQ.details);
    setCurrentSupplierName(inquiryRespondingCompany.responseCompanyName);
  }, [selectedRFQ]);

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return "";
    const formattedPhoneNumber = phoneNumber.replace(/^0/, "");
    return "+263" + formattedPhoneNumber.replace("+263","");
  };
  const generateDropDown = (thisSupplier: any) => {
    const items: MenuProps["items"] = [
      {
        key: "0",
        icon: <PhoneOutlined />,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"tel:+263772128622"}
          >
            Phone: +263 772 123 456
          </a>
        ),
      },
      {
        key: "1",
        icon: <PhoneOutlined />,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://dialer.supplyzone.ai/?phoneNumber=${formatPhoneNumber(
              thisSupplier !== undefined
                ? thisSupplier.primaryContact.phoneNumber
                : ""
            )}`}
          >
            Voip:{" "}
            {formatPhoneNumber(
              thisSupplier !== undefined
                ? thisSupplier.primaryContact.phoneNumber
                : ""
            )}
          </a>
        ),
      },
      {
        key: "2",
        icon: <WhatsAppOutlined />,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://wa.me/+263772128622?text=Test"}
          >
            WhatsApp: +263 772 123 456
          </a>
        ),
      },
      {
        key: "3",
        icon: <MailOutlined />,
        label: (
          <a href={`#contact-form`}>
            Email:{" "}
            {thisSupplier !== undefined
              ? thisSupplier.primaryContact.emailAddress
              : ""}
          </a>
        ),
      },
      
    ];

    return items;
  };
  return (
    <>
      {selectedRFQ && (
        <>
          {contextHolder}
          {/*.  begin::Card body  .*/}
          <div className="rfq-details-wrapper">
            {/*.  begin::Messages  .*/}

            <div className="row mb-1 compact-col">
              <div className="col-md-2">
                <span className="fs-7 fw-bold">
                  <strong>
                    {String(props.listViewLabel) === "RFQ" ? "RFQ" : "Inquiry"}
                    #:
                  </strong>
                </span>
              </div>

              <div className="col-md-3">
                <span className="fs-7 fw-bold">
                  {String(props.listViewLabel) === "RFQ" ? "RFQ" : "INQ"}1
                  {selectedRFQ.id.toString().padStart(4, "0")}
                </span>
              </div>

              <div className="col-md-3">
                <span className="fs-7 fw-bold">
                  <strong>Qty:</strong>
                </span>
              </div>

              <div className="col-md-4">
                <span className="fs-7 fw-bold">
                  {String(props.listViewLabel) === "RFQ" ? (
                    <>
                      {selectedRFQ.estimatedOrderQuantity.size}{" "}
                      {selectedRFQ.estimatedOrderQuantity.unitOfMeasurement}
                    </>
                  ) : (
                    <>
                      {selectedRFQ.lineItems &&
                        selectedRFQ.lineItems[0].quantity}
                    </>
                  )}
                </span>
              </div>

              <div className="col-md-2">
                <span className="fs-7 fw-bold">
                  <strong>Posted:</strong>
                </span>
              </div>

              <div className="col-md-3">
                <span className="fs-7 fw-bold">
                  {getTime(selectedRFQ.createdAt)}
                </span>
              </div>

              <div className="col-md-3">
                <span className="fs-7 fw-bold">
                  <strong>Unit Price:</strong>
                </span>
              </div>

              <div className="col-md-4">
                <span className="fs-7 fw-bold">
                  {String(props.listViewLabel) === "RFQ" ? (
                    <>
                      {selectedRFQ.preferredUnitPrice.amount == 0
                        ? "Not specified"
                        : selectedRFQ.preferredUnitPrice.currency +
                          " " +
                          selectedRFQ.preferredUnitPrice.amount}{" "}
                    </>
                  ) : (
                    <>
                      {"USD "}
                      {new Intl.NumberFormat().format(
                        (
                          selectedRFQ.estimatedValue /
                          selectedRFQ.lineItems[0].quantity
                        ).toFixed(2)
                      )}
                    </>
                  )}
                </span>
              </div>

              <div className="col-md-2">
                <span className="fs-7 fw-bold">
                  <strong>Expires:</strong>
                </span>
              </div>
              <div className="col-md-3">
                <span className="fs-7 fw-bold">
                  {String(props.listViewLabel) === "RFQ" ? (
                    <>{getTime(selectedRFQ.validTo)}</>
                  ) : (
                    <>{getTime(selectedRFQ.expirationDate)}</>
                  )}
                </span>
              </div>

              <div className="col-md-3">
                <span className="fs-7 fw-bold">
                  <strong>Source Type:</strong>
                </span>
              </div>
              <div className="col-md-4">
                {String(props.listViewLabel) === "RFQ" ? (
                  <>Business Service</>
                ) : (
                  <>{"Business"}</>
                )}
              </div>
            </div>

            <div className="row mb-1">
              <hr />
              <Popover
                content={
                  <>
                    {String(props.listViewLabel) === "RFQ" ? (
                      <>{selectedRFQ.productName}</>
                    ) : (
                      <>
                        {selectedRFQ.lineItems &&
                          selectedRFQ.lineItems[0].productName}
                      </>
                    )}
                  </>
                }
                title="Product Name"
              >
                <div className="col-md-5">
                  <strong>
                    Product Name: <MdInfoOutline className="info-icon" />
                  </strong>
                  <br />
                  {String(props.listViewLabel) === "RFQ" ? (
                    <>{selectedRFQ.productName}</>
                  ) : (
                    <>
                      {selectedRFQ.lineItems &&
                        selectedRFQ.lineItems[0].productName}
                    </>
                  )}
                </div>
              </Popover>
              <Popover
                content={
                  <>
                    {String(props.listViewLabel) === "RFQ" ? (
                      <>{currentDescription} </>
                    ) : (
                      <>{selectedRFQ.details}</>
                    )}
                  </>
                }
                title="Description"
              >
                <div className="col-md-7">
                  <strong>
                    Description: <MdInfoOutline className="info-icon" />{" "}
                  </strong>
                  <br />
                  <span className="one-liner-text">
                    {String(props.listViewLabel) === "RFQ" ? (
                      <>{currentDescription} </>
                    ) : (
                      <>{selectedRFQ.details}</>
                    )}
                  </span>
                </div>
              </Popover>
              <hr />
            </div>

            <div className="row mb-1 rfq-description-wrapper">
              <div className="col-md-12 ">
                <strong>Suppliers:</strong>
                <br />
                {String(props.listViewLabel) === "RFQ" ? (
                  <>
                    {suppliers.length > 0 ? (
                      <>
                        {suppliers.map(
                          (supplier: any, supplierIndex: number) => (
                            <Dropdown
                              key={supplierIndex}
                              menu={{ items: generateDropDown(supplier) }}
                              arrow
                            >
                              <Tag
                                className="mb-1 pr-2 pl-2 pt-1 pb-1" style={{cursor: "pointer"}}
                                key={supplierIndex}
                              >
                                {supplier.name} <CaretDownOutlined />
                              </Tag>
                            </Dropdown>
                          )
                        )}
                      </>
                    ) : (
                      <>None</>
                    )}
                  </>
                ) : (
                  <>
                    {currentSupplierName.length > 0 && (
                      <Dropdown
                        menu={{ items: generateDropDown(suppliers[0]) }}
                        arrow
                      >
                        <Tag className="mb-1 pr-2 pl-2 pt-1 pb-1" style={{cursor: "pointer"}}>
                          {currentSupplierName} <CaretDownOutlined />
                        </Tag>
                      </Dropdown>
                    )}
                  </>
                )}
              </div>
            </div>

            {/*.  end::Messages  .*/}
          </div>
          {/*.  end::Card body  .*/}
        </>
      )}
    </>
  );
};

export default BuyerInquiryDetails;
