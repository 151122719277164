import axios from "axios";
import Snippets from "./Snippets";

const baseURL = Snippets.urls.getAPIBaseURL();

//Create Axios Instance
const AxiosAPI = {
  getQueryVariable: (windowObj: any, key: any) => {
    //todo: doesn't belong

    if (typeof windowObj !== undefined) {
      var query = windowObj.location.search.substring(1);
      var vars = query.split("&");
      //console.log(2222, vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        //console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
        if (pair[0] == key) {
          return pair[1];
        }
      }
    }
    return "";
  },
  searchAllItems: (query: string="") => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/search-all?searchQuery="+query+"",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    return axios(config);
  },
  getRandomProducts: () => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/random-products?pageSize=30",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    return axios(config);
  },
  getTopSuppliers: () => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/top-suppliers?pageSize=30",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    return axios(config);
  },
  getLatestRFQs: () => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/latest-rfqs?pageSize=30",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    return axios(config);
  },
  saveProfileData: (payload: any) => {
    var config = {
      method: "post",
      url: baseURL + "/profile",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: payload,
    };
    return axios(config);
  },
  saveAvatarAttachment: (file: any, test: any, big: any) => {
    var config = {
      method: "post",
      url: baseURL + "/profile/user-avatar",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: file,
    };
    return axios(config);
  },
  saveBusinessAttachment: (file: any) => {
    var config = {
      method: "post",
      url: baseURL + "/profile/upload-document",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: file,
    };
    return axios(config);
  },
  removeFileAttachment: (s3Key: any) => {
    var config = {
      method: "delete",
      url: baseURL + "/profile/delete-document/" + s3Key,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getProfileData: () => {
    var config = {
      method: "get",
      url: baseURL + "/profile",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getProfileCompleteness: (profileId: number) => {
    var config = {
      method: "get",
      url: baseURL + `/profile/${profileId}/completeness`,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellersRepresentingCategory: (categoryId: number) => {
    var config = {
      method: "get",
      url: baseURL + `/marketplace/category/${categoryId}/suppliers`,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getCompanyProfile: (_token: any = "") => {
    var config = {
      method: "get",
      url: baseURL + "/profile",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  savePlainMessage: (
    messageId: number,
    messageText: any,
    attachmentFile: any
  ) => {
    var messageData = {
      id: messageId,
      message: messageText,
      messageAttachment: "",
    };
    if (attachmentFile.hasOwnProperty("size") && attachmentFile.size > 10) {
      messageData.messageAttachment = attachmentFile;
    } else {
      //delete messageData["messageAttachment"];
    }
    var config = {
      method: "post",
      url: baseURL + "/messages/",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: messageData,
    };
    return axios(config);
  },
  saveFirstMessage: (id: number, messageText: any, attachmentFile: any) => {
    var messageData: any = {
      id: 0,
      inquiryHeaderId: id,
      status: "NEW",
      message: messageText,
      inquiryDetails: [],
      messageAttachment: "",
    };
    if (attachmentFile.hasOwnProperty("size") && attachmentFile.size > 10) {
      messageData.messageAttachment = attachmentFile;
    } else {
      // delete messageData.messageAttachment;
    }
    var config = {
      method: "post",
      url: baseURL + "/supplier/inquiries/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: messageData,
    };
    return axios(config);
  },
  saveProduct: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/supplier/save-product",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config);
  },

  saveProductAttachment: (
    productId: any,
    attachment: any,
    attachmentType: any
  ) => {
    const data = {
      name: attachment.meta.name,
      attachmentType: attachmentType,
      size: attachment.meta.size,
      type: attachment.meta.type,
      data: attachment.data,
      entityId: productId,
      isPrimary: false,
      imageIndex: attachment.imageIndex
    };

    data.isPrimary = attachment.isPrimary ? true : false

    var config = {
      method: "post",
      url: baseURL + "/supplier/upload-attachment",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },

  getProductAttachment: (id: any) => {
    var config = {
      method: "get",
      url: baseURL + "/home/product-attachment/" + id,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  deleteProductAttachment: (productId: any, s3Key: any) => {
    const data = {
      productId: productId,
      attachmentName: s3Key,
    };
    var config = {
      method: "post",
      url: baseURL + "/supplier/delete-attachment",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config);
  },
  getUOMs: () => {
    var config = {
      method: "get",
      url: baseURL + "/model/uoms",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  inviteMultipleSuppliers: (suppliers:  Array<any>) => {
    var config = {
      method: "post",
      url: baseURL + "/buyer/invite-multiple-suppliers",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: suppliers
    };
    return axios(config);
  },
  getSupplierInvites: () => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/supplier-invites",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  activateSupplier: (supplierData: any) => {
    var config = {
      method: "patch",
      url: baseURL + "/supplier/activate",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: supplierData
    };
    return axios(config);
  },
  updateSupplierFields: (supplierData: any) => {
    var config = {
      method: "patch",
      url: baseURL + "/supplier/update-fields",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: supplierData
    };
    return axios(config);
  },
  revokeInvite: (inviteId: number) => {
    var config = {
      method: "patch",
      url: baseURL + "/buyer/invite-supplier/" + inviteId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        status: false
      }
    };
    return axios(config);
  },
  resendInvite: (inviteId: number) => {
    var config = {
      method: "patch",
      url: baseURL + "/buyer/invite-supplier/" + inviteId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        status: true
      }
    };
    return axios(config);
  },
  getProduct: (productId: string) => {
    var config = {
      method: "get",
      url: baseURL + "/home/product/" + productId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getCategoryList: () => {
    var config = {
      method: "get",
      url: baseURL + '/model/categories',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getChildCategoryList: (parentCategoryId: number) => {
    var config = {
      method: "get",
      url: baseURL + '/model/categories/'+parentCategoryId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getUserProfile: (token: string = null) => {
    if (!token) {
      token = Snippets.user.getCurrentUserAccessToken();
    }
    var config = {
      method: "get",
      url: baseURL + "/me",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerProfile: (buyerId: number) => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/users/"+buyerId,
      headers: {
        //Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getMarketplaceRFQs: (_params: any) => {

    let endpoint: string = "/marketplace/rfqs";

    if (Object.keys(_params).length > 0 && ("q" in _params)) {

      endpoint = "/marketplace/search-rfqs?searchQuery=" + _params["q"];

      if ("p" in _params) {
        endpoint = endpoint + "&pageNumber=" + (parseInt(_params["p"]) - 1);
      }

      if ("s" in _params) {
        endpoint = endpoint + "&pageSize=" + _params["s"];
      }

    }

    var config = {
      method: "get",
      url: baseURL + endpoint,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },
  saveInquiry: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/buyer/inquiry/save-inquiry",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config);
  },
  getUserProducts: () => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/search-products/0/100",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getUserRFQS: () => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs?notInFolder=1",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getRFQ: (id:number) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs/"+id,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getRFQFolder: (folderName:string) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/rfqs/by-folder?folder="+folderName,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  saveRFQFolder: (userId: number, rfqId:number, folderName:string) => {
    
    var config = {
      method: "post",
      url: baseURL + "/supplier/rfqs/add-to-folder",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        "appUserId": userId,
        "requestForQuoteId": rfqId,
        "folder": folderName
      },
    };
    return axios(config);
  },
  getUserInquiries: () => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/inquiry/get-inquiries",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerRFQs: () => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/rfqs",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerRFQResponses: (rfqId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/rfqs/" + rfqId + "/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerRFQResponses: (rfqId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs/responses/" + rfqId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerInquiries: () => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/inquiries",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerRFQDetails: (rfqId: number) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/rfqs/" + rfqId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerRFQList: () => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerInquiryDetails: (inquiryId: number) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/inquiries/" + inquiryId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getUserInquiryResponses: (inquiryId: number) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/inquiries/" + inquiryId + "/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerInquiryResponses: (inquiryId: number) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/inquiries/responses/" + inquiryId + "",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerBuyers: () => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/buyers-list",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerSellers: () => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/suppliers-list",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getAllSellers: () => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/suppliers",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  editRFQDetails: (id: number, productDetails:string) => {
    var config = {
      method: "patch",
      url: baseURL + `/buyer/rfqs/${id}`,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { id, productDetails }
    };
    return axios(config);
  },
  getCategorySellers: (categoryId: number = 0) => {
    if (categoryId === 0) {
      return AxiosAPI.getBuyerSellers();
    }
    var config = {
      method: "get",
      url: baseURL + "/marketplace/category/" + categoryId + "/suppliers",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerSellerRFQResponses: (rfqId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs/" + rfqId + "/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerRFQResponsesChats: (responseId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs/responses/" + responseId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getBuyerINQResponsesChats: (responseId: any, inquiryId: any) => {
    var config = {
      method: "get",
      url:
        baseURL + "/buyer/inquiries/" + inquiryId + "/responses/" + responseId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerRFQResponsesChats: (responseId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/rfqs/" + responseId + "/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getSellerINQResponsesChats: (responseId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/inquiries/responses/" + responseId,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  replyMessage: (threadId: any, message: string) => {
    var config = {
      method: "post",
      url: baseURL + "/messages/",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        id: threadId,
        message: message,
      },
    };
    return axios(config);
  },
  replyMessageAttachment: (
    threadId: any,
    message: string,
    file: any,
    fileData: any = "",
    attchmentType: string = "IMAGE"
  ) => {
    var config = {
      method: "post",
      url: baseURL + "/messages/",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        id: threadId,
        message: message,
        messageAttachment: {
          name: file.name,
          attachmentType: attchmentType ? attchmentType : "IMAGE",
          size: file.size,
          type: file.type,
          data: fileData,
          entityId: 0,
          isPrimary: true,
          imageIndex: 0,
        },
      },
    };
    return axios(config);
  },
  replyRFQ: (requestForQuoteId: any, message: string) => {
    var config = {
      method: "post",
      url: baseURL + "/supplier/rfqs/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        id: requestForQuoteId,
        requestForQuoteId: requestForQuoteId,
        message: message,
      },
    };
    return axios(config);
  },
  replyInquiry: (requestForQuoteId: any, message: string, selectedRFQ: any) => {
    var config = {
      method: "post",
      url: baseURL + "/supplier/inquiries/responses",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        id: requestForQuoteId,
        status: "NEW",
        inquiryHeaderId: requestForQuoteId,
        message: message,
        inquiryDetails: [
          {
            id: selectedRFQ.lineItems[0].id,
            productId: selectedRFQ.lineItems[0].productId,
            quantity: selectedRFQ.lineItems[0].quantity,
            unitPrice: 0,
          },
        ],
      },
    };
    return axios(config);
  },
  getSellersRepresentingCategory2: (categoryId: number) => {
    var config = {
      method: "get",
      url: baseURL + `/marketplace/category/${categoryId}/suppliers`,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  saveRFQ: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/buyer/rfqs",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config);
  },
  saveRFQAttachment: (rfqId: number, attachment: any, attachmentType: any) => {
    //{ "id": productId, "data": reader.result, "attachmentType": _file.type, "meta": {type: _file.type.split("/")[0].toUpperCase(), size:_file.size, name:_file.name, lastModified:_file.lastModified}, "s3Key": "string" }
    const data = {
      name: attachment.meta.name,
      attachmentType: attachmentType,
      size: attachment.meta.size,
      type: attachment.meta.type,
      data: attachment.data,
      isPrimary: true,
      entityId: rfqId,
      imageIndex: 0,
    };
    var config = {
      method: "post",
      url: baseURL + "/buyer/rfqs/attachment",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config);
  },

  loginUser: (username: any, password: any) => {
    var config = {
      method: "post",
      url: baseURL + "/token",
      headers: {
        Authorization: "Basic " + btoa(username + ":" + password),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  registerUser: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/register/register-user",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },

  registerUserV2: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/register/v2/register-user",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },

  requestOTP: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/register/send-mfa-code",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },

  isEmailRegistered: (emailAddress: any) => {
    var config = {
      method: "post",
      url: baseURL + "/register/is-email-registered",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        email: emailAddress,
      },
    };

    return axios(config);
  },

  changePassword: (oldPassword: any, newPassword: any) => {
    var config = {
      method: "post",
      url: baseURL + "/profile/users/change-password",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    };

    return axios(config);
  },

  addRFQToFolder: (folder:string, id:number, userData:any) => {
    
    const url = userData.profile.profileType === "BUYER" ? "/buyer/rfqs/add-to-folder" : "/supplier/rfqs/add-to-folder";
    var config = {
      method: "post",
      url: baseURL + url,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        "appUserId": userData.id,
        "requestForQuoteId": id,
        "folder": folder
      },
    };

    return axios(config);
  },

  getRFQsByFolder: (folder: string) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/rfqs/by-folder?folder=" + folder,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  addInquiryToFolder: (folder:string, id:number, userData:any) => {
    var config = {
      method: "post",
      url: baseURL + "/buyer/inquiries/add-to-folder",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        "appUserId": userData.id,
        "inquiryId": id,
        "folder": folder
      },
    };

    return axios(config);
  },

  getInquiriesByFolder: (folder: string) => {
    var config = {
      method: "get",
      url: baseURL + "/buyer/inquiries/by-folder?folder=" + folder,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getCategoriesForDropdown: (id: number = 0) => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/category/" + id,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getCategory: (id: number) => {
    var config = {
      method: "get",
      url: baseURL + "/model/category/" + id,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getCategories: () => {
    var config = {
      method: "get",
      url: baseURL + "/model/categories",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getCategoryChildren: (
    parentId: number,
    pageNumber?: number,
    pageSize?: number
  ) => {
    var url = baseURL + "/model/categories/" + parentId;
    if (typeof pageNumber !== "undefined") {
      url = `${baseURL}/model/categories/${parentId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    var config = {
      method: "get",
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  saveRFQDocument: (data: any) => {
    var config = {
      method: "post",
      url: baseURL + "/buyer/rfqs",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },

  inviteSupplier: (inviteFrom:string, inviteFromId: string, email: string, name: string) => {
    var config = {
      method: "post",
      url: baseURL + "/buyer/invite-supplier",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        name: name,
        inviteFrom: inviteFrom,
        inviteFromId: inviteFromId,
      },
    };
    return axios(config);
  },

  getProductDetails: (id: any) => {
    var config = {
      method: "get",
      url: baseURL + "/home/product/" + id,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getAllProducts: () => {
    var config = {
      method: "get",
      url: baseURL + "/home/search-products/0/100",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getCategoryProducts: (categoryId: number, recursive: boolean = true) => {
    var url = baseURL + "/marketplace/category/" + categoryId + "/products";
    if (recursive) {
      url = url + "?recursive=1";
    }
    var config = {
      method: "get",
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getSupplierProducts: (supplierId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/supplier/" + supplierId + "/products/0/7",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getSupplierList: () => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/suppliers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getCategorySupplierList: (categoryId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/category/" + categoryId + "/suppliers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getSupplierDetails: (supplierId: any) => {
    var config = {
      method: "get",
      url: baseURL + "/marketplace/supplier/" + supplierId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getProductsByKeyword: (keyword: any) => {
    var config = {
      method: "get",
      url: baseURL + "/home/search-products/0/1000/" + keyword,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getMyProducts: () => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/search-products/0/1000",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getMyProductsByKeyword: (keyword: any) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/search-products/0/1000/" + keyword,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  getMyProductDetails: (id: number) => {
    var config = {
      method: "get",
      url: baseURL + "/supplier/product/" + id,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },
  getProfileUsers: () => {
    var config = {
      method: "get",
      url: baseURL + "/profile/users",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },
  apiRequest: (method: string, url: string, data: any) => {
    var config = {
      method: method,
      url: baseURL + url,
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },
  publicApiRequest: (method: string, url: string, data: any) => {
    var config = {
      method: method,
      url: baseURL + url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config);
  },

  getRewardsTransactions: () => {
    var config = {
      method: "get",
      url: baseURL + "/rewards/v1/transactions",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getRewardsSummary: () => {
    var config = {
      method: "get",
      url: baseURL + "/rewards/v1/summary",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getRewardsLevels: () => {
    var config = {
      method: "get",
      url: baseURL + "/rewards/v1/levels",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  getRewardsEventTypes: () => {
    var config = {
      method: "get",
      url: baseURL + "/rewards/v1/event-types",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
  setRewardsEvent: (event:string, meta: any) => {
    var config = {
      method: "post",
      url: baseURL + "/rewards/v1/event",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        "event": event,
        "meta": JSON.stringify(meta)
      }
    };
    return axios(config);
  },
  redeemRewards: (email:string, rewardId: number) => {
    var config = {
      method: "post",
      url: baseURL + "/rewards/v1/redeem",
      headers: {
        Authorization: "Bearer " + Snippets.user.getCurrentUserAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        email, rewardId
      }
    };
    return axios(config);
  },
  getRewardsItems: () => {
    var config = {
      method: "get",
      url: baseURL + "/super/reward-prizes/v1",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
};
export default AxiosAPI;

export { AxiosAPI }
