import {
  CloudDownloadOutlined,
  LinkedinFilled,
  MailOutlined,
  ShareAltOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import React, { FunctionComponent } from "react";
import "./document-attachment.scss";
import { Dropdown, MenuProps } from "antd";
const DocumentAttachments: FunctionComponent<any> = ({
  inquiryRespondingCompany,
  attachment,
  children,
  ...props
}) => {
  const [shareViaWhatsApp, setShareViaWhatsApp] = React.useState("");
  const [shareViaFacebook, setShareViaFacebook] = React.useState("");
  const [shareViaTwitter, setShareViaTwitter] = React.useState("");
  const [shareViaInstagram, setShareViaInstagram] = React.useState("");
  const [shareViaLinkedIn, setShareViaLinkedIn] = React.useState("");

  React.useEffect(() => {
    const shareLink = `https://api-live.supplyzone.ai/home/download-attachment/${attachment.s3Key}`;

    setShareViaWhatsApp(
      `https://api.whatsapp.com/send?text=${attachment.name} ${shareLink}`
    );
    setShareViaFacebook(
      `https://www.facebook.com/sharer/sharer.php?app_id=1217981644879628&u=${shareLink}&igshid=MDc3MzNhZDY0Ng==`
    );
    setShareViaTwitter(
      `https://twitter.com/share?url=${shareLink}&text=${attachment.name}&via=#WebsiteBrowser&hashtags=#SupplyZone`
    );
    setShareViaInstagram(
      `https://www.instagram.com/sharer/sharer.php?app_id=1217981644879628&u=${shareLink}&igshid=MDc3MzNhZDY0Ng==`
    );
    setShareViaLinkedIn(
      `https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`
    );
  }, [attachment]);

  type MenuItem = Required<MenuProps>["items"][number];
  const itemsDownload: MenuItem[] = [
    {
      key: "0",
      icon: <CloudDownloadOutlined />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://api-live.supplyzone.ai/home/download-attachment/${attachment.s3Key}`}
        >
          Download
        </a>
      ),
    },
  ];
  const itemsShare: MenuItem[] = [
    {
      key: "0",
      icon: <CloudDownloadOutlined />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaWhatsApp}>
          Share via WhatsApp
        </a>
      ),
    },
    {
      key: "3",
      icon: <TeamOutlined />,
      label: (
        <a href={`#c`}>
          Share via Teams
        </a>
      ),
    },
    {
      key: "3",
      icon: <MailOutlined />,
      label: (
        <a href={`#`}>
          Share via Email
        </a>
      ),
    },
    {
      key: "4",
      icon: <LinkedinFilled />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={shareViaLinkedIn}>
          Share via Linked In
        </a>
      ),
    },
  ];
  return (
    <>
      <div className="col-span-full abcd mt-1">
        {inquiryRespondingCompany && (
          <a
            href={`https://api-live.supplyzone.ai/home/download-attachment/${attachment.s3Key}`}
            target="_blank"
            rel="no-follow"
          >
            <div className="chat-list attachment-list-item">
              <div className="row">
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-2 pr-1">
                  <div
                    className="doc-wrapper d-nonex"
                    style={{ backgroundImage: "none !important" }}
                  >
                    <span className="centeredText"> </span>
                    {attachment.name.includes(".pdf") && (
                      <img
                        src="/assets/images/docs/pdf.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".doc") && (
                      <img
                        src="/assets/images/docs/doc.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".xls") && (
                      <img
                        src="/assets/images/docs/xls.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".csv") && (
                      <img
                        src="/assets/images/docs/xls.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".jpg") && (
                      <img
                        src="/assets/images/docs/jpg.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".jpeg") && (
                      <img
                        src="/assets/images/docs/jpg.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".png") && (
                      <img
                        src="/assets/images/docs/jpg.png"
                        alt=""
                        width="36"
                      />
                    )}
                    {attachment.name.includes(".txt") && (
                      <img
                        src="/assets/images/docs/txt.png"
                        alt=""
                        width="36"
                      />
                    )}
                  </div>
                </div>

                <div
                  className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"
                  style={{ paddingTop: "13px" }}
                >
                  <span className="one-liner-text">
                    {attachment.name}
                  </span>
                  {inquiryRespondingCompany.responseCompanyName &&
                    inquiryRespondingCompany.responseCompanyName !== "" && (
                      <>
                        <span className="one-liner-text">
                          <small>{inquiryRespondingCompany.responseCompanyName}</small>
                        </span>
                      </>
                    )}
                  {/* 
                  {attachment.senderCompany &&
                    attachment.senderCompany !== "" && (
                      <span className="one-liner-text">
                        {attachment.senderCompany}
                      </span>
                    )}
                  {String(
                    `${inquiryRespondingCompany.responseCompanyContactNumber}`
                  ).length > 5 && (
                      <>
                        <br />
                        <strong>Contact Number: </strong>
                        {inquiryRespondingCompany.responseCompanyContactNumber}
                      </>
                    )}

                    */}
                  <Dropdown
                    className="hide-in-mobile"
                    menu={{ items: itemsDownload }}
                  >
                    <CloudDownloadOutlined
                      style={{
                        position: "absolute",
                        right: "-25%",
                        top: "30%",
                        fontSize: "20px",
                      }}
                    />
                  </Dropdown>

                  <Dropdown
                    className="hide-in-mobile"
                    menu={{ items: itemsShare }}
                  >
                    <ShareAltOutlined
                      style={{
                        position: "absolute",
                        right: "-75%",
                        top: "30%",
                        fontSize: "20px",
                      }}
                    />
                  </Dropdown>
                </div>
              </div>
            </div>
          </a>
        )}
      </div>
    </>
  );
};

export default DocumentAttachments;
