import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Row } from "antd";
import axios from "axios";
import { MdAutoAwesome, MdClear, MdMood, MdMoreVert, MdRefresh, MdSend } from "react-icons/md";
import ChatGPTGreeting from "./ChatGPTGreeting";
import ChatGPTSpeechBubble from "./ChatGPTSpeechBubble";
import { PaperClipOutlined } from "@ant-design/icons";
import "./Chat.scss";
import loadingDiscs from "./loading.svg";
import AxiosAPI from "../../../helpers/AxiosAPI";
import { useAppDataContext } from "../../../data/context/AppDataContext";
interface DocumentListItemProps {
    children?: React.ReactNode;
    onChatGPTDrawerClose: any;
    selectedRFQ: any
}
interface GPTMessage {
    role: string;
    content: any;
}
interface Supplier {
    name: string;
    primaryContact: any;
}
const ChatGPT2: React.FC<DocumentListItemProps> = ({ children, ...props }) => {
    const {
        userData,
    } = useAppDataContext();
    const { onChatGPTDrawerClose, selectedRFQ }: any = props;
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const textareaWrapperRef = useRef<HTMLDivElement | null>(null);
    const [sendButtonColor, setSendButtonColor] = useState("#777777");


    const defaultMessages = [
        {
            role: "system",
            content: "You are an assistant for a Procurement Officer, or a Procurement Supervisor,for a SaaS procurement system called Supply Zone."
        },
        {
            role: "system",
            content: "When requested for a table, do not add **bold** or *italic* formatting."
        },
        {
            role: "system",
            content: "When providing a table with quotations, if there are other columns with terms and conditions, you can combine them as one column and name it note. For example, if there are columns for warranty, payment terms, and lead time, you can combine them into one column called notes."
        },
        {
            role: "system",
            content: `Supply Zone is similar to Alibaba, in that it facilitates B2B sourcing.`
        },
        {
            role: "system",
            content: "The particular area for you to focus on is the ‘Messenger’ page, which is similar to Alibaba Messenger."
        },
        {
            role: "system",
            content:
                `if asked to summarize what the rfq is about, express the rfq details in a tabular format, and do not include quotes when describing the rfq. 
            Table format to be in 2 columns, first is the key field and the other for the value .
            You can however indicate how many quotes you have received, if any, thus far.`
        },
        {
            role: "system",
            content: "For the question: Get current status on this inquiry, only show RFQ details and do not list the submitted quotes."
        },
        {
            role: "system",
            content: `When asked to evaluate quotations or negotiate with suppliers and they are no quotations received: Show the user the notified suppliers in tabular format(Supplier Name|Contact Email|Contact number) and let the user know that no quotations have been received yet.
                You will know there are no quotations when the user does not send an image to you, so check messages for images.
            `
        },
        {
            role: "system",
            content: "Do not say 'no quotations have been received' without ensuring this is in fact the case"
        },
        //no quotes - show suppliers in table format
        {
            role: "system",
            content: `
                On the messenger page, there are a list of RFQ’s, or Request for Quotations.
                    This is the primary organizing concept.
                    a. There are two classes of organizations on Supply Zone: Buying 
                    Organizations, and Supplier Organizations. An organization can have 
                    multiple users on Supply Zone.
                    b. Buying Organizations can invite new suppliers, can create a list of 
                    pre-approved suppliers, or utilize Supply Zone’s extended network of 
                    vetted suppliers.
                    c. A Buying Organization seeks to streamline procurement activities by 
                    publishing RFQ’s which are then distributed to qualified Supplier 
                    Organizations for response.
                    d. A Supplier Organization seeks to receive genuine and high value 
                    RFQ’s from reputable Buying Organizations, and therefore pays for 
                    various tiers of membership on Supply Zone.
                    e. Supply Zone provides membership to a Supplier Organization to one 
                    or more categories, of which there are currently 13 categories.
                    f. An RFQ is created by a user
                    g. An RFQ has a creation date and an expiry date
                    h. An RFQ is considered active or open if the expiry date has not lapsed
                    i. An RFQ is considered expired or closed if the expiry date has lapsed.
                    j. An RFQ has a category, of which there are approximately 13, such as 
                    Automotive, Construction, Hardware, etc.
                    k. An RFQ has a description.
                    l. An RFQ has a unique number to identify it.
                    m. A supplier who is not currently a Supply Zone member, can be invited 
                    by a Buyer to join the system.
                    n. A supplier may be on a pre-approved list of the Buying Organization
                    o. A Buying Officer has various time consuming tasks that they must 
                    carry out daily, and you are to assist in improving their productivity, 
                    and over time, carry out the function autonomously.
                    i. A buying officer has to check the status of RFQs that are active,
                    and ensure that she responds to any messages from suppliers in
                    a timely manner.
                    ii. A buying officer has to ensure that a good number of quotes are
                    received, ideally between 3 to 9. If a supplier has not responded
                    within 24 hours, a follow up message should be sent to that 
                    supplier. Further, 24 hours before the expiry of an RFQ, a final 
                    reminder message should be sent to suppliers who have not yet 
                    sent quotes.
                    iii. A buying officer has to create a comparison table to compare 
                    prices of any given commodity between suppliers, taking into 
                    account price including VAT, price excluding VAT, payment 
                    terms, payment currency which is usually USD, warranty terms,
                    and lead time.
                    iv. When you do a comparison table, you want to compare ‘apples 
                    to apples’, so the commodity must be the same, currency must 
                    be the same, and so on. If there is a problem with a quote, 
                    which are normally PDF’s or Excel or Word documents, you 
                    should mention it to the Buying Officer, so corrections can be 
                    done if needed.
                    v. A buying officer has to then summarize the supplier responses, 
                    perform an analysis and make recommendations on which 
                    supplier(s) to choose.
                    vi. The buying officer may need to send further emails to negotiate
                    prices or terms with short-listed suppliers.
                    vii. All the RFQ information and attributes are organized within a 
                    relational database, which you should have access to.
                    viii. Overall you should seek to increase the productivity of the 
                    buying officer.
                    ix. Overall, you should seek to advance the interests of the buying 
                    organization.
                    x. You may be asked to create summaries for buying activity in a 
                    given time period, for a given category, for a given supplier, and
                    for a given buying officer.
                    xi. If you are not sure of anything, you can ask for clarification.
                `
        }

    ];
    const [messages, setMessages] = useState<GPTMessage[]>(defaultMessages)


    const handleButtonClick = () => {
        if (
            fileInputRef &&
            fileInputRef.current !== null &&
            "click" in fileInputRef.current
        ) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log("Selected file:", file);
            // Process the file here
            setAttachment(file);
            setAttachmentName(file.name);
        }
    };


    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const [chatMessage, setChatMessage] = useState("");
    const [attachmentName, setAttachmentName] = useState("");
    const [attachment, setAttachment] = useState<File | null>(null);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [messageRead, setMessageRead] = useState<boolean>(false);
    const [unreadMessages, setUnreadMessages] = useState<number>(0);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSendMessage = () => {
        sendMessage(chatMessage);
    };

    const handleChangedMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setChatMessage(e.target.value);
    };

    const handleTypingMessage = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            // Send message on Enter key (without Shift)
            e.preventDefault(); // Prevent line break
            sendMessage(chatMessage);
        }
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height

            if (textareaWrapperRef.current) {
                textareaWrapperRef.current.style.height = "auto"; // Reset height
                textareaWrapperRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
            }
        }

        setTimeout(() => { }, 500);
    };

    const onAutoResponseHandler = (text: string, event?: React.MouseEvent) => {
        sendMessage(text);
        if (event) {
            const clickedElement = event.currentTarget;
            clickedElement.classList.add('selected');
          }
    };

    const sendMessage = async (chatMessage: string): Promise<void> => {
        if (chatMessage.trim() !== "") {
            setMessages(
                [
                    ...messages,
                    {
                        role: "user",
                        content: chatMessage
                    }
                ]
            )

            setChatMessage("");
            setAttachmentName("");
            setAttachment(null);
            setIsTyping(false);

            try {


                if (textareaRef.current) {
                    textareaRef.current.style.height = "52px"; // Reset height

                    if (textareaWrapperRef.current) {
                        textareaWrapperRef.current.style.height = "52px"; // Reset height
                    }
                }
            } catch (error) {
                setIsTyping(false);
            }
        }
    };

    useEffect(() => {
        const handleFocus = () => {
            setMessages((prevMessages) =>
                prevMessages.map((message) => ({ ...message, isRead: true }))
            );
            setMessageRead(true);
            setUnreadMessages(0);
        };

        const handleScroll = () => {
            setMessages((prevMessages) =>
                prevMessages.map((message) => ({ ...message, isRead: true }))
            );
            setMessageRead(true);
            setUnreadMessages(0);
        };

        window.addEventListener("focus", handleFocus);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("focus", handleFocus);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => { }, [messages]);
    React.useEffect(() => {
        if (messages[messages.length - 1].role == 'user') {
            console.log("DEBUG__ | should send to gpt now", messages);
        }
        //return;
        if (messages[messages.length - 1].role == 'user') {
            //the system attached the files/quotations, so we wait for user's message to initiate the GPT
            if (Array.isArray(messages[messages.length - 1].content)) {
                return;
            }

            let responseType = 'text';
            setIsTyping(true);
            scrollToBottom()
            var config = {
                method: 'POST',
                url: 'https://api.openai.com/v1/chat/completions',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 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`,
                    'OpenAI-Organization': 'org-RGzukEAhJUMRdCtmrCCeI4Df'
                },
                data: JSON.stringify({
                    model: 'gpt-4o-mini',
                    messages: messages,
                    max_tokens: 16383,
                    temperature: 0.60,
                    response_format: { type: responseType }
                }),
            };

            axios(config).then((res: any) => {
                //console.log(res)
                if (res.data.choices) {

                    setMessages(
                        [
                            ...messages,
                            {
                                role: 'assistant',
                                content: res.data.choices[0].message.content //processGPTContent
                            }

                        ]
                    )
                } else {
                    setMessages(
                        [
                            ...messages,
                            {
                                role: 'assistant',
                                content: 'I don’t have enough information on that topic yet, but I’d be happy to help with related questions or explore this further with you!'
                            }

                        ]
                    )
                }
                setIsTyping(false);


            })
        }
    }, [messages]);

    React.useEffect(() => {

        //fetch attachments and then make the query to GPT
        AxiosAPI.getBuyerSellerRFQResponses(selectedRFQ.id)
            .then((res: any) => {

                let listData = res.data;

                let attachments: any[] = [];
                const suppliersWhoResponded: Supplier[] = [];
                listData.map((supplierResponse: any) => {
                    suppliersWhoResponded.push({
                        name: supplierResponse.supplierCompanyName,
                        primaryContact: supplierResponse.primaryContact,
                    });
                    supplierResponse.messageThread.thread.map((chat: any): void => {
                        if (chat.s3Key && chat.s3Key.length > 4) {
                            attachments.push({
                                name: chat.attachmentName,
                                type: chat.attachmentType,
                                s3Key: chat.s3Key,
                                modified: chat.date.split("T")[0],
                                senderCompany: chat.senderCompany,
                            });
                        }
                    });
                });

                //we have the attachments now
                const newMessages: GPTMessage[] = [
                    {
                        role: "system",
                        content: "The selected RFQ is: " + JSON.stringify(selectedRFQ)
                    }
                ]
                const pdfQuotations = new Map<string, string>()
                if (attachments) {
                    let hasQuotation = false;
                    attachments.map((attachment: any) => {
                        if (attachment.name.includes(".png") || attachment.name.includes(".jpg") || attachment.name.includes(".jpeg")) {
                            hasQuotation = true;
                            newMessages.push(
                                {
                                    role: "user",
                                    content: [
                                        {
                                            type: "text",
                                            text: `Quotation from ${attachment.senderCompany}.`
                                        },
                                        {
                                            type: "image_url",
                                            image_url: {
                                                url: `https://api-live.supplyzone.ai/home/download-attachment/${attachment.s3Key}`
                                            }
                                        }
                                    ]
                                }
                            )
                        }

                        if (attachment.name.includes(".pdf")) {
                            pdfQuotations.set(attachment.s3Key, attachment.senderCompany);
                        }

                    })
                    newMessages.push(
                        {
                            role: "system",
                            content: "If user doesn't send an image to you then assume that no quotations where submitted."
                        }
                    )
                }

                //console.log("DEBUG__ | in chatgpt", [selectedRFQ, attachments]);

                //attach notified suppliers
                AxiosAPI.getBuyerSellers()
                    .then((res: any) => {

                        let notifiedSuppliers = res.data.map(({ name, primaryContact }: Supplier) => ({
                            name,
                            primaryContact,
                        }));

                        notifiedSuppliers = [...notifiedSuppliers, ...suppliersWhoResponded];

                        newMessages.push(
                            {
                                role: "system",
                                content: "The following suppliers have been notified about the RFQ: " + JSON.stringify(notifiedSuppliers)
                            }
                        )

                        //logged in user
                        if (userData.person && userData.person.firstName) {
                            newMessages.push(
                                {
                                    role: "system",
                                    content: `The current user is: ${userData.person.firstName} ${userData.person.lastName}`
                                }
                            )
                        }


                        //pdf quotations
                        if (pdfQuotations.size > 0) { //wait for pdf to png and then set the messages
                            var config = {
                                method: "get",
                                url: `https://hyperefficient2.net/sz-chat-gpt/pdf_to_png.php?key=${Array.from(pdfQuotations.keys()).join(',')}`
                            };
                            axios(config).then((response: any) => {
                                response.data.map((pngFile: string) => {
                                    newMessages.push(
                                        {
                                            role: "user",
                                            content: [
                                                {
                                                    type: "text",
                                                    text: `Quotation from ${pdfQuotations.get(pngFile.replace('.png', '.pdf'))}.`
                                                },
                                                {
                                                    type: "image_url",
                                                    image_url: {
                                                        url: `https://hyperefficient2.net/sz-chat-gpt/pdf_to_png/${pngFile}`
                                                    }
                                                }
                                            ]
                                        }
                                    )
                                })

                                setMessages(
                                    [
                                        ...defaultMessages,
                                        ...newMessages
                                    ]
                                )
                            })

                        } else {
                            setMessages(
                                [
                                    ...defaultMessages,
                                    ...newMessages
                                ]
                            )
                        }

                    })
                    .catch(() => { });



            })
            .catch(() => { });


    }, [selectedRFQ])

    React.useEffect(() => {
        setSendButtonColor(chatMessage.trim() !== "" ? "#552278" : "#777777");
    }, [chatMessage]);

    return (
        <div className="sai-chat-wrapper">
            <div className="sai-chat-header">
                <Row wrap={false} style={{ margin: "8px" }} gutter={8}>
                    <Col flex="none">
                        {messageRead ? (
                            <div className="sai-avatar">
                                <MdAutoAwesome size={18} />
                            </div>
                        ) : (
                            <Badge dot>
                                <div data-unread={`${unreadMessages}`} className="sai-avatar">
                                    <MdAutoAwesome size={18} />
                                </div>
                            </Badge>
                        )}
                    </Col>
                    <Col flex="auto">
                        <span className="sai-logo-text">SimplexAI
                            <span className="badge"
                                style={{
                                    backgroundColor: "#542277",
                                    color: "#fff",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    fontSize: "12px",
                                    marginLeft: "15px"
                                }}>{selectedRFQ.id}</span></span>
                    </Col>

                    <Col flex="none">
                        <Button
                            type="text"
                            size="large"
                            icon={<MdRefresh size={32} color="#999" />}
                            onClick={() => {
                                setMessages([]);
                            }}
                        />
                    </Col>
                    <Col flex="none">
                        <Button
                            type="text"
                            size="large"
                            icon={<MdMoreVert size={32} color="#999" />}
                        />
                    </Col>
                    <Col flex="none">
                        <Button
                            type="text"
                            size="large"
                            icon={<MdClear size={32} color="#999" />}
                            onClick={() => {
                                onChatGPTDrawerClose()
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <div className="sai-chat-body">
                <div className="scroller">
                    <div className="imessage">
                        <ChatGPTGreeting onAutoResponse={onAutoResponseHandler} />
                        {messages
                            .filter((message: any) => message.role !== 'system' && !Array.isArray(message.content))
                            .map((message: any, messageIndex: number) => (
                                <ChatGPTSpeechBubble
                                    key={messageIndex}
                                    content={message.content}
                                    isLocal={message.role == 'user'}
                                    onAutoResponse={onAutoResponseHandler}
                                />
                            ))}
                    </div>
                    {isTyping && (
                        <img
                            style={{ width: 48, opacity: 0.4, margin: 24 }}
                            src={loadingDiscs}
                        />
                    )}
                    <div ref={messagesEndRef} style={{ marginBottom: 50 }} />
                </div>

                <Card
                    ref={textareaWrapperRef}
                    className={`sai-prompt-wrapper`}
                    bordered={false}
                >
                    <Row wrap={false} style={{ margin: "3px" }} gutter={8}>
                        <Col flex="none">
                            <Button
                                shape="circle"
                                type="text"
                                size="large"
                                icon={<MdMood size={32} color="#777777" />}
                            />
                        </Col>
                        <Col flex="auto">
                            <textarea
                                ref={textareaRef}
                                className="sai-input"
                                placeholder="Type your message here..."
                                value={chatMessage}
                                onChange={handleChangedMessage}
                                onKeyUp={handleTypingMessage}
                            />
                        </Col>

                        <Col flex="none">
                            <Button
                                shape="circle"
                                type="text"
                                size="large"
                                icon={
                                    <PaperClipOutlined
                                        style={{ fontSize: "26px", color: "#777777" }}
                                        color="#777777"
                                    />
                                }
                                onClick={handleButtonClick}
                            />
                        </Col>
                        <Col flex="none">
                            <Button
                                onClick={handleSendMessage}
                                shape="circle"
                                type="text"
                                size="large"
                                style={{ color: sendButtonColor }}
                                icon={<MdSend size={26} />}
                            />
                        </Col>
                    </Row>
                </Card>
                <input
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    type="file"
                    name="attachment"
                    id="collaboration-attachment"
                />
                {attachment === null ? (
                    <span className="sai-disclaimer">
                        Check Simplex responses for incorrect info.{" "}
                        <a href="#">Privacy Statement</a>
                    </span>
                ) : (
                    <span className="sai-disclaimer">
                        <PaperClipOutlined />
                        Selected File: <strong>{attachmentName}</strong>
                    </span>
                )}
            </div>
        </div>
    )
}
export { ChatGPT2 };