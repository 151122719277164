import React, { useEffect, useState } from "react";
import "./Chat.scss";
import { Card, Col, Row } from "antd";
import { useAppDataContext } from "../../../data/context/AppDataContext";

interface IGreeting {
  onAutoResponse: (text: string,event?: React.MouseEvent) => void;
}

const ChatGPTGreeting: React.FC<IGreeting> = ({  onAutoResponse }) => {
  const [user, setUser] = useState<string>("");
  const {
    userData,
  } = useAppDataContext();
    React.useEffect(() => {
      if(userData.person && userData.person.firstName){
        setUser(userData.person.firstName + " " + userData.person.lastName) 
      } 
    }, [userData]);
  return (
    <>
      <h2 className="sai-greeting">
        Hi there, {user}. What would you like to do today?
      </h2>

        <Row gutter={8}>
        <Col
          key={`d-4`}
          xs={{ flex: "50%" }}
          sm={{ flex: "50%" }}
          md={{ flex: "50%" }}
          lg={{ flex: "50%" }}
          xl={{ flex: "50%" }}
        >
          <Card
            size="small"
            className="mb-3 greeting-prompt"
            onClick={(e) => {
              onAutoResponse("Get current status on this inquiry", e);
            }}
            style={{ cursor: "pointer" }}
          >
            <span>
              <strong>Summarize RFQ</strong>
            </span>
            <br />
            <span>Get current status on this inquiry</span>
          </Card>
        </Col>
        <Col
          key={`d-4`}
          xs={{ flex: "50%" }}
          sm={{ flex: "50%" }}
          md={{ flex: "50%" }}
          lg={{ flex: "50%" }}
          xl={{ flex: "50%" }}
        >
          <Card
            size="small"
            className="mb-3 greeting-prompt"
            onClick={() => {
              onAutoResponse("Compare quotes and unit prices");
            }}
            style={{ cursor: "pointer" }}
          >
            <span>
              <strong>Evaluate Quotations</strong>
            </span>
            <br />
            <span>Compare quotes and and unit prices</span>
          </Card>
        </Col>
        <Col
          key={`d-4`}
          xs={{ flex: "50%" }}
          sm={{ flex: "50%" }}
          md={{ flex: "50%" }}
          lg={{ flex: "50%" }}
          xl={{ flex: "50%" }}
        >
          <Card
            size="small"
            className="mb-3 greeting-prompt"
            onClick={() => {
              onAutoResponse("Lets do a comparison of non price factors, but include a column for unit price");
            }}
            style={{ cursor: "pointer" }}
          >
            <span>
              <strong>Evaluate Non Price Factors</strong>
            </span>
            <br />
            <span>Evaluate terms and conditions of supplier offer</span>
          </Card>
        </Col>
        <Col
          key={`d-4`}
          xs={{ flex: "50%" }}
          sm={{ flex: "50%" }}
          md={{ flex: "50%" }}
          lg={{ flex: "50%" }}
          xl={{ flex: "50%" }}
        >
          <Card
            size="small"
            className="mb-3 greeting-prompt"
            style={{ cursor: "pointer" }}
          >
            <span>
              <strong>Market Intelligence Report</strong>
            </span>
            <br />
            <span>Market Intelligence Report</span>
          </Card>
        </Col>
      </Row> 
     
    </>
  );
};

export default ChatGPTGreeting;