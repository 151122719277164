import React, { FC, useEffect, useRef, useState } from "react";
import MessengerAdvancedListItem from "./MessengerAdvancedListItem";
import { format } from "date-fns";
import {
  Avatar,
  Badge,
  Col,
  Dropdown,
  Empty,
  List,
  MenuProps,
  Row,
  Skeleton,
  Switch,
} from "antd";
import Icon, {
  StarOutlined,
  LikeOutlined,
  MessageOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  FolderFilled,
  MoreOutlined,
} from "@ant-design/icons";
import { useAppDataContext } from "../../../../data";
import { useParams } from "react-router-dom";

const Fade = require("react-reveal/Fade");

const MessengerAdvancedInquiryCol1: any = ({
  listItems,
  listViewLabel,
  selectedRFQIndex,
  setSelectedRFQHandler,
  userType,
  stillLoading,
}: any) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { rfqId } = useParams();

  const scrollToItem = (index: number) => {
    //console.log("::scrollToItem::", index);
    return;
    if (itemRefs.current[index]) {
      itemRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleClick = (index: number) => {
    console.log("::handleClick::", index);
    if (itemRefs.current[index]) {
      itemRefs.current[index].click();
    }
  };

  const getTime: Function = (timeString: string): string => {
    if (!timeString) return timeString;
    return format(new Date(timeString), "yyyy-MMM-dd");
  };

  const [rfqsSearch, setRfqsSearch] = useState("");

  const [listviewLoaded, setListviewLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      scrollToItem(selectedRFQIndex);
    }, 10);
  }, [selectedRFQIndex]);

  useEffect(() => {
    if (listItems.length > 0) {
      if (rfqId !== undefined && rfqId !== null) {
        setTimeout(() => {
          scrollToItem(parseInt(rfqId));
          handleClick(parseInt(rfqId));
        }, 10);
      }
    }
  }, [listItems]);

  interface IconTextProps {
    icon: typeof Icon;
    text: React.ReactNode;
  }

  const listData = Array.from({ length: 3 }).map((_, i) => ({
    href: "https://ant.design",
    title: `ant design part ${i + 1}`,
    avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  }));

  const IconText: React.FC<IconTextProps> = ({ icon, text }: any) => (
    <>
      {React.createElement(icon, { style: { marginRight: 8 } })}
      {text}
    </>
  );

  const [loading, setLoading] = useState(true);

  const [currentFolderName, setCurrentFolderName] = useState("Active");

  const {
    currentlySelectedFolder,
    setCurrentlySelectedFolder,
    userDocumentsInquiries,
    userDocumentsRFQs,
    userDocumentsRFQsActive,
    userDocumentsRFQsCompleted,
    userDocumentsRFQsArchived,
    userDocumentsInquiriesActive,
    userDocumentsInquiriesCompleted,
    userDocumentsInquiriesArchived,
  } = useAppDataContext();

  const itemsPhone: MenuProps["items"] = [
    {
      key: "0",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-all`}
          href={"#"}
          onClick={() => {
            switchToFolder("ACTIVE");
          }}
        >
          Active {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
          <span className="folder-badge">
            (
            {listViewLabel === "RFQ"
              ? userDocumentsRFQs.length
              : userDocumentsInquiries.length}
            )
          </span>
        </a>
      ),
    },
    {
      key: "1",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-completed`}
          href={"#"}
          onClick={() => {
            switchToFolder("COMPLETED");
          }}
        >
          Completed {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
          <span className="folder-badge">
            (
            {listViewLabel === "RFQ"
              ? userDocumentsRFQsCompleted.length
              : userDocumentsInquiriesCompleted.length}
            )
          </span>
        </a>
      ),
    },
    {
      key: "2",
      icon: <FolderOpenOutlined />,
      label: (
        <a
          className={`doc-folder-archived`}
          href={"#"}
          onClick={() => {
            switchToFolder("ARCHIVED");
          }}
        >
          Archived {listViewLabel === "RFQ" ? "RFQs" : "Inquiries"}
          <span className="folder-badge">
            (
            {listViewLabel === "RFQ"
              ? userDocumentsRFQsArchived.length
              : userDocumentsInquiriesArchived.length}
            )
          </span>
        </a>
      ),
    },
  ];

  const switchToFolder: any = (folder: string) => {
    setCurrentlySelectedFolder(folder);
    switch (folder) {
      case "ALL": {
        setCurrentFolderName("Active");
        break;
      }
      case "ACTIVE": {
        setCurrentFolderName("Active");
        break;
      }
      case "COMPLETED": {
        setCurrentFolderName("Completed");
        break;
      }
      case "ARCHIVED": {
        setCurrentFolderName("Archived");
        break;
      }
      default: {
        setCurrentFolderName("Active");
        break;
      }
    }
  };

  const toTitleCase: any = (str: string): string => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const [isDomReady, setIsDomReady] = useState(false);

  useEffect(() => {
    if (!listItems || listItems.length === 0) {
      setTimeout(() => {
        setIsDomReady(true);
      }, 5000);
    }
  }, [listItems]);

  return (
    <>
      <div className="xcard card-with-title">
        <div className="title-link-wrapper mb-3">
          <h2 className="title mb-0 pt-2 pb-2">
            {listViewLabel === "RFQ"
              ? toTitleCase(currentlySelectedFolder) + " RFQs"
              : toTitleCase(currentlySelectedFolder) + " Inquiries"}{" "}
            ({listItems.length})
          </h2>
          <Dropdown menu={{ items: itemsPhone }} arrow>
            <MoreOutlined
              style={{
                right: -25,
                top: -12,
                position: "absolute",
                fontSize: "32px",
              }}
            />
          </Dropdown>
        </div>
        <div className="list-view-wrapper">
          <div className="rfq-search-container">
            <input
              type="text"
              className="form-control inquiry-search"
              placeholder={
                listViewLabel === "RFQ"
                  ? "Search " + currentlySelectedFolder.toLowerCase() + " RFQs"
                  : "Search " +
                    currentlySelectedFolder.toLowerCase() +
                    " Inquiries"
              }
              value={rfqsSearch}
              onChange={(e) => {
                setRfqsSearch(e.target.value);
              }}
            />
            <i className="w-icon-search"></i>
          </div>

          <div className="rfq-list-container mt-3">
            <br />

            {stillLoading ? (
              <>
                {isDomReady ? (
                  <Empty className="d-none" />
                ) : (
                  <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={listData}
                    renderItem={(item) => (
                      <List.Item
                        key={item.title}
                        actions={
                          !loading
                            ? [
                                <IconText
                                  icon={StarOutlined}
                                  text="156"
                                  key="list-vertical-star-o"
                                />,
                                <IconText
                                  icon={LikeOutlined}
                                  text="156"
                                  key="list-vertical-like-o"
                                />,
                                <IconText
                                  icon={MessageOutlined}
                                  text="2"
                                  key="list-vertical-message"
                                />,
                              ]
                            : undefined
                        }
                        extra={
                          !loading && (
                            <img
                              width={272}
                              alt="logo"
                              src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                            />
                          )
                        }
                      >
                        <Skeleton loading={loading} active avatar>
                          <List.Item.Meta
                            avatar={<Avatar src={item.avatar} />}
                            title={<a href={item.href}>{item.title}</a>}
                            description={item.description}
                          />
                          {item.content}
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                )}
              </>
            ) : (
              <>
                {listItems.length > 0 ? (
                  <>
                    {listItems
                      .filter((q: any) => {
                        return listViewLabel === "RFQ"
                          ? q.productName
                              .toLowerCase()
                              .indexOf(rfqsSearch.toLowerCase()) > -1
                          : q.lineItems[0].productName
                              .toLowerCase()
                              .indexOf(rfqsSearch.toLowerCase()) > -1 ||
                              q.lineItems[0].productDescription
                                .toLowerCase()
                                .indexOf(rfqsSearch.toLowerCase()) > -1 ||
                              q.lineItems[0].category.name
                                .toLowerCase()
                                .indexOf(rfqsSearch.toLowerCase()) > -1 ||
                              (
                                "1" +
                                q.lineItems[0].id.toString().padStart(4, "0")
                              )
                                .toLowerCase()
                                .indexOf(rfqsSearch.toLowerCase()) > -1 ||
                              (
                                "10" +
                                q.lineItems[0].id.toString().padStart(3, "0")
                              )
                                .toLowerCase()
                                .indexOf(rfqsSearch.toLowerCase()) > -1 ||
                              (
                                "100" +
                                q.lineItems[0].id.toString().padStart(2, "0")
                              )
                                .toLowerCase()
                                .indexOf(rfqsSearch.toLowerCase()) > -1;
                      })
                      .map((listItem: any, listItemIndex: number) => (
                        <div
                          key={listItemIndex}
                          ref={(el) => (itemRefs.current[listItemIndex] = el)}
                          style={{
                            width: "100%",
                            padding: "0px",
                          }}
                        >
                          <Fade
                            key={listItemIndex}
                            bottom
                            appear
                            delay={(250 * listItemIndex) / 2}
                          >
                            <MessengerAdvancedListItem
                              key={listItemIndex}
                              listItem={listItem}
                              isListView={false}
                              listItemIndex={listItemIndex}
                              selectedRFQIndex={selectedRFQIndex}
                              setSelectedRFQHandler={() =>
                                setSelectedRFQHandler(listItem, listItemIndex)
                              }
                              listViewLabel={listViewLabel}
                              userType={userType}
                              getTime={getTime}
                              showViewDetails={false}
                            />
                          </Fade>
                        </div>
                      ))}
                  </>
                ) : (
                  <Empty className="d-none" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MessengerAdvancedInquiryCol1;
